<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter"
      parallax-active="true"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              <img :src="image_head_title"/>
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div v-if="isLoading" style="height: 200px">
            <div id="loading">
              <div class="spinner"></div>
            </div>
          </div>
          <div v-else>
            <collapse-f-a-q :active-tab="0"
                            :collapse="boardFAQ.map((item) => item)"
                            icon="keyboard_arrow_down">
              <template v-for="(item, index) in boardFAQ">
                <template :slot="`md-collapse-pane-${index + 1}`">
                  <!--<p :key="index">-->
                    <!--{{index}} : {{item.content}}-->
                  <!--</p>-->
                  <p v-html="item.content" :key="index"></p>
                </template>
              </template>
            </collapse-f-a-q>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Mixins from "@/plugins/basicMixins";
  import {
    CollapseFAQ
  } from "@/components";

  export default {
    bodyClass: "ac-news-faq",
    mixins: [Mixins.HeaderImage],
    components: {
      CollapseFAQ
    },
    computed: {
      ...mapGetters([
        "locale",
        "language",
        "base",
        "boardFAQ",
        "isLoading",
      ]),
    },
    mounted() {
      this.GET_FAQ_DATA();
    },
    watch: {
      locale() {
        this.GET_FAQ_DATA();
      }
    },
    methods: {
      ...mapActions([
        'GET_FAQ_DATA',
      ]),
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/pages/news/04_news_bg2-2.png"),
        image_head_title: require("@/assets/img/pages/news/faq.png"),
      };
    },
  }

</script>
<style lang="scss">
  .ac-news-faq {

    .wrapper {
      /*
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        top: -50%;
        left: -50%;
        background-image: url("../../assets/img/bg/00_pattern1.png");
        background-repeat: repeat;
        background-position: 0 0;
        transform: rotate(-30deg);
      }
      */

      .main-raised {
        background-color: #18182f;
        min-height: 50vh;

        .section {
          padding: 50px 0;
        }
      }
    }
  }

</style>